<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <h1 class="text-3xl mb-5 ml-3">Tabulações / Etiquetas</h1>

    <div class="mt-5 md:mt-0 md:col-span-2">
      <form action="#" method="POST" autocomplete="off">
        <div class="px-5 pb-4">
          <div class="grid grid-cols-12 gap-6">
            <div class="col-span-12 md:col-span-6">
              <label for="name" class="block text-sm font-medium">Nome</label>
              <input v-model="form.nome" type="text" name="name" id="name" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
              <label class="text-sm font-medium mx-2">
                Usar etiqueta para finalizar conversas
                <input type="checkbox" class="ml-1 rounded-md" v-model="form.etiquetaParaFinalizarConversas" @input="() => (form.etiquetaParaFinalizarConversas = !form.etiquetaParaFinalizarConversas)" />
              </label>
              <label class="text-sm font-medium mx-2">
                Personalizar cor da etiqueta
                <input type="checkbox" class="ml-1 rounded-md" v-model="form.editarCorDaEtiqueta" @input="() => (form.editarCorDaEtiqueta = !form.editarCorDaEtiqueta)" />
              </label>
              <div v-if="form.editarCorDaEtiqueta">
                <label class="block text-lg font-medium mb-2">Cor da etiqueta:</label>
                <sketch-picker v-model="form.corDaEtiqueta" />
              </div>
            </div>
            <div class="col-span-12 md:col-span-6">
              <label for="descricao" class="block text-sm font-medium">Descrição</label>
              <textarea
                v-model="form.descricao"
                rows="4"
                type="text"
                name="descricao"
                id="descricao"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              ></textarea>
            </div>
            <div class="col-span-12 md:col-span-6">
              <label for="centrocusto" class="block text-sm font-medium">Centro de Custo</label>
              <select v-model="form.centrocusto" required name="centrocusto" id="centrocusto" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                <option :value="null">Geral</option>
                <option v-for="c in centroscusto" :key="c._id" :value="c._id">
                  {{ c.nome }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-2 grap-6">
          <div class="px-5 py-3 text-left sm:px-6">
            <router-link
              :to="`/tag`"
              class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Voltar
            </router-link>
          </div>
          <div class="px-5 py-3 text-right sm:px-6">
            <button
              @click="save"
              type="button"
              class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400"
            >
              Salvar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumbs.vue";
import { Sketch } from "vue-color";

export default {
  components: {
    Breadcrumb,
    "sketch-picker": Sketch
  },
  data() {
    return {
      breadcrumb: [
        { url: "/config", value: "Configuração" },
        { url: "/tag", value: "Tabulações / Etiquetas" },
        { url: "/tag/form", value: "Novo / Editar" }
      ],
      centroscusto: [],
      form: {
        nome: "",
        descricao: "",
        corDaEtiqueta: "#000000",
        centrocusto: null,
        etiquetaParaFinalizarConversas: false,
        editarCorDaEtiqueta: false
      },
    };
  },

  methods: {
    async save() {
      this.form.corDaEtiqueta = this.form.corDaEtiqueta.hex ? this.form.corDaEtiqueta.hex : this.form.corDaEtiqueta;

      const method = this.form._id ? "put" : "post";
      const req = await this.$http[method](`/v1/tag`, this.form);

      if (req.data.success) {
        this.$vToastify.success("Salvo com sucesso!");
        this.$router.push({ path: `/tag` });
      } else {
        this.$vToastify.error(req.data.err);
      }
    }
  },
  async beforeMount() {
    const id = this.$route.params.id;

    if (id) {
      const req = await this.$http.get(`/v1/tag/${id}`);
      console.log(req.data)
      this.form = req.data;
    }

    const centroscustoReq = await this.$http.post(`/v1/centrocusto/list`, { all: true });
    this.centroscusto = centroscustoReq.data.data;
  }
};
</script>
